import React from 'react'
import { compose } from 'recompose'
import { withAuthorization } from '../components/session'
import { Wrap, Title } from '../components/page/components'
import SEO from '../components/SEO'
import FocusHeader from '../components/global/focus-header'
import FocusBody from '../components/page/focus-body'
import Cancel from '../components/account/cancel'

const AccountCancel = ({ authUser, location }) => {
  const backUrl =
    location.state && location.state.prevPath ? location.state.prevPath : '/'

  return (
    <Wrap>
      <SEO title="Cancel Account" />
      <FocusHeader
        title="Account"
        subTitle="Cancel Account"
        titleLink="/account"
        backUrl={backUrl}
      />
      <FocusBody>
        <Title>Cancel Account</Title>
        <Cancel authUser={authUser} />
      </FocusBody>
    </Wrap>
  )
}

const condition = authUser => !!authUser
const AccountWithAuth = compose(withAuthorization(condition))(AccountCancel)
export default AccountWithAuth
