import React, { useState } from 'react'
import { withFirebase } from '../firebase'
import {
  Button,
  FormBox,
  FormFields,
  FormField,
  FormCopy,
} from '../page/components'
import { Link } from 'gatsby'

const INITIAL_STATE = {
  cancelling: false,
  cancelled: false,
  cancelErrors: [],
}

const Cancel = ({ firebase, authUser }) => {
  if (!firebase) return null

  const [status, setStatus] = useState(INITIAL_STATE)

  const cancelAccount = () => {
    if (status.cancelling) return false

    if (authUser && authUser.uid) {
      const r = window.confirm(
        'Are you sure? This will permanently delete your account and any related data.'
      )

      if (r === true) {
        setStatus(prevState => ({
          ...prevState,
          cancelling: true,
          cancelled: false,
          cancellErrors: null,
        }))

        const userDeleteFunc = firebase.functions.httpsCallable('dbUserDelete')
        const funcData = {
          studentId: authUser.uid,
        }

        userDeleteFunc(funcData)
          .then(res => {
            console.log('✔ Account deleted', res)
            setStatus(prevState => ({
              ...prevState,
              cancelling: false,
              cancelled: true,
              cancellErrors: null,
            }))
            firebase.doSignOut()
            window.location.href = '/account-goodbye'
          })
          .catch(error => {
            const { message } = error
            // setErrors([`We had a problem removing the account! ${message}`])
            console.log(`✘ Error ${message}`)
            setStatus(prevState => ({
              ...prevState,
              cancelling: false,
              cancelled: false,
              cancelErrors: [message],
            }))
          })
      }
    } else {
      setStatus(prevState => ({
        ...prevState,
        cancelErrors: ["You don't seem to be logged in :)"],
      }))
    }
  }

  const errorContent =
    status.cancelErrors.length > 0
      ? status.cancelErrors.map((e, index) => <p key={`erorr-${index}`}>{e}</p>)
      : null

  const buttonText = status.cancelling
    ? `Cancelling Account...`
    : `Cancel Account`

  return (
    <FormBox>
      <FormFields isProcessing={status.cancelling}>
        <FormField width={`100%`}>
          <FormCopy>
            To cancel your account please click the cancel button below.
            <br />
            <br />
            Please note this will take immediate effect and cannot be undone.
          </FormCopy>
        </FormField>
        {errorContent}
        <FormField marginRight={`50%`}>
          <Button onClick={cancelAccount}>{buttonText}</Button>
        </FormField>
        <FormField marginRight={`50%`}>
          <FormCopy>
            <p>
              <Link to="/account">Back</Link>
            </p>
          </FormCopy>
        </FormField>
      </FormFields>
    </FormBox>
  )
}

export default withFirebase(Cancel)
